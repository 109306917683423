<template>
    <div style="height:100%">
        <div class="page-header">运营自动审批</div>
        <div style="margin:20px;text-align:right;">
            <el-input style="max-width:250px;" placeholder="搜索企业" 
                prefix-icon="el-icon-search" v-model="inputValue">
            </el-input>
        </div>
        <div class="block" style="height: calc(100% - 167px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="name" label="申请企业" width="120">
                        <template slot-scope="scope">
                            <div>{{scope.row.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="privs" label="申请资源">
                        <template slot-scope="scope">
                            <div>{{scope.row.ziyuan}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="申请数量">
                        <template slot-scope="scope">
                            <div>{{scope.row.num}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="申请日期">
                        <template slot-scope="scope">
                            <div>{{scope.row.time}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="申请人">
                        <template slot-scope="scope">
                            <div>{{scope.row.person}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="申请说明">
                        <template slot-scope="scope">
                            <div>{{scope.row.desc}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div>
        </div>

        
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    export default {
        components: {
            
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                queryOpt:{
                    pageNumber:1,
                    pageCount:15,
                },
                total:0,
                list:[],
                inputValue:"",
            }
        },
        mounted() {
            this.list.push({
                id:"111",
                name:"xxxxx家具品牌公司",
                ziyuan:"若风",
                num:124,
                time:"2020/1/12",
                person:"某某",
                desc:"申请说明",
            })
        },
        created() {
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    
    
</style>